import { createSlice } from "@reduxjs/toolkit"
import { PurchaseMep, TransferMep } from "../components/investments/interfaces/types"

type ModalType = "CREATE_TRANSACTION" | "DETAIL_TRANSACTION" | "CREATE_PURCHASE" | "DETAIL_PURCHASE" | null

type OperationData = PurchaseMep | TransferMep | null

const initialState = {
    open: false,
    modalType: null as ModalType,
    operationData: null as OperationData,
}

const mepModal = createSlice({
  name: "Mep Modal",
  initialState,
  reducers: {
    onUpdateMepModal: (state, action) => {
      return { ...state, ...action.payload }
    },
    onResetMepModal: () => {
      return { ...initialState }
    },
  },
})

export const { onUpdateMepModal, onResetMepModal } = mepModal.actions

export default mepModal.reducer
